var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-3"},[_c('Page-Header',{attrs:{"title":_vm.$t('branches.branches'),"icon":"mdi-file-tree"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary darken-1"},nativeOn:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add"))+" ")],1)]},proxy:true}])}),_c('v-data-table',{staticClass:"pa-5",attrs:{"headers":_vm.headers,"items":_vm.branches,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary darken-1","dark":""}},[_c('v-card-title',[_c('span',{},[_vm._v(_vm._s((_vm.editedIndex === -1 ? _vm.$t("add") : _vm.$t("edit")) + " " + _vm.$t("branches.branch")))])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","hint":"5 characters only!","rules":_vm.rules,"counter":5,"label":_vm.$t(
                                                            'branches.branchCode'
                                                        )},model:{value:(
                                                        _vm.editedItem.branchCode
                                                    ),callback:function ($$v) {_vm.$set(_vm.editedItem, "branchCode", $$v)},expression:"\n                                                        editedItem.branchCode\n                                                    "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","rules":[
                                                        function (value) { return !!value ||
                                                            'Required.'; }
                                                    ],"label":_vm.$t(
                                                            'branches.branchName'
                                                        )},model:{value:(
                                                        _vm.editedItem.branchName
                                                    ),callback:function ($$v) {_vm.$set(_vm.editedItem, "branchName", $$v)},expression:"\n                                                        editedItem.branchName\n                                                    "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t(
                                                            'branches.branchUrl'
                                                        )},model:{value:(
                                                        _vm.editedItem.branchUrl
                                                    ),callback:function ($$v) {_vm.$set(_vm.editedItem, "branchUrl", $$v)},expression:"\n                                                        editedItem.branchUrl\n                                                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"outlined":"","dense":"","hide-details":"","persistent-hint":"","items":_vm.branchTypes,"item-text":"key","label":_vm.$t(
                                                            'branches.branchType'
                                                        )},model:{value:(
                                                        _vm.editedItem.branchType
                                                    ),callback:function ($$v) {_vm.$set(_vm.editedItem, "branchType", $$v)},expression:"\n                                                        editedItem.branchType\n                                                    "}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary darken-1","loading":_vm.loading,"min-width":100},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"text":"","color":"red"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete,"toolBarColor":"red darken-2"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                                                    var item = ref.item;
return [(item.branchCode != 'MAIN')?_c('div',{staticClass:"text-end"},[(_vm.isInRole('3'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]):_vm._e(),(_vm.isInRole('4'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loading,"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])]):_vm._e()],1):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }